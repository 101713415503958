.form__label {
    font-family: 'Roboto', sans-serif;
    font-size: .8rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    transform: translateY(0rem);
  }
  
  .form__input {
    font-family: 'Roboto', sans-serif;
    color:black;
    font-size: .85rem;
      margin: 0 auto;
    padding: .3rem .3rem;
    border-radius: 0.5rem;
    background-color: rgb(255, 255, 255);
    border-color: rgb(61, 132, 208);
    width: 100%;
    display: block;
    transition: all 0.3s;
  }
  
  .error-message {
    color: #f25061; 
    font-size: 0.75rem;
    margin-top: 0.25rem;
    font-weight: bold; 
  }
