.discount-card {
    position: relative;
    width: 100%;
    height: 100px;
    background: var(--secondary_blue);
    border-radius: 10px;
    padding: 10px;
    color: #58595b;
    font-family: Arial, sans-serif;
    overflow: hidden;
    margin-bottom: .5rem;
}

.content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    z-index: 0;
    margin-left: 2rem;
    width: 100%;
}

.main-text {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    cursor: pointer;

}

.sub-text {
    font-size: 1rem;
    margin: 0;
}

.validity {
    font-size: 0.8rem;
    margin: 0;
}

.price {
    font-size: .85rem;
    font-weight: bold;
    margin: 0;
    font-style: italic;
}

.gift-icon {
    position: absolute;
    margin-top: 2rem;
    background-size: contain;
    top: 0;
    /* bottom:-20; */
    opacity: 0.4;
    right: 1rem;
}

.discount-card::before,
.discount-card::after {
    content: '';
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--card_color);
    border-radius: 50%;
    top: 10px;
    transform: translateY(50%);
}

.discount-card::before {
    left: -20px;
}

.discount-card::after {
    right: -20px;
}

@media (min-width: 600px) {
    .discount-card {
        width: 100%;
    }
}

@media (min-width: 900px) {
    .discount-card {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    .discount-card {
        width: 40%;
    }
}

.addToCart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.addButton {
    margin-right: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 2rem;

}

.bn3637 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    color: #58595b;
    ;
    backface-visibility: hidden;
    border: 0.3rem solid transparent;
    border-radius: 1rem;
    /* z-index: 1; */
}

.bn38 {
    border-color: transparent;
    transition: background-color 0.3s ease-in-out;
}

.bn38:hover {
    color: #A0D4FF;
}