 .indicator.magic {
     animation: bounce .3s infinite alternate;
 }

 @keyframes bounce {
     0% {
         transform: translateY(0);
     }

     100% {
         transform: translateY(-20px);
     }
 }


 .navigation {
     width: 100%;
     height: 3.5rem;
     position: relative;
     background: var(--card_color);
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .navigation ul {
     display: flex;
     justify-content: space-between;
     width: 100%;
     padding: 0;
     margin-right: 0rem;
     margin-left: .5rem;
     margin-right: .5rem;

 }

 .navigation ul li {
     list-style: none;
     position: relative;
     width: 3.5rem;
     height: 3.5rem;
     z-index: 1;
     bottom:.2rem
 }

 .navigation ul li a {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100%;
     text-align: center;
     font-weight: 600 !important;
 }

 .navigation ul li a .icon {
     display: block;
     position: relative;
     line-height: 4rem;
     z-index: 1;
     font-size: .7rem;
     text-align: center;
     transition: 0.2s;
 }

 .navigation ul li.active a .iconcolor {
     /* stroke: #fff;
    fill: #fff; */
     /* opacity: 1; */
 }

 /* .navigation ul li.active {
    transform: translateY(10px);
    width: 3.2rem;
    height: 3.2rem;
    background-color: var(--footer_indicator);
    border: 4px solid var(--footer_indicator);
    border-radius: 50%;
    box-shadow: 5;
    cursor: pointer;
} */

 .navigation ul li.active a .iconcolorHome {
     /* fill: #fff; */
 }

 /* .navigation ul li.active a .icon {
    z-index: -1;
    transform: translateY(-13px) translateX(0px);
} */

 .navigation ul li a .text {
     position: absolute;
     color: var(--secondary_text);
     
     font-size: 0.7rem;
     margin-top: 3rem;
 }

 /* .navigation ul li.active a .text {
    transform: translateY(-11px);
    color: #068fff;
    display: none;
} */

 .divider {
     width: 1px;
     height: 2.5rem;
     background-color: #ccc;
     position: absolute;
     bottom: .3rem;
     left: 4rem
 }