.drawer {
  scrollbar-width: thin;
  scrollbar-color: #888 #fff;
}

.drawer::-webkit-scrollbar {
  width: 10px;
}

.drawer::-webkit-scrollbar-track {
  background: #fff;
}

.drawer::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 20px;
  border: 3px solid #fff;
}
