.css-yilf1d-MuiInputBase-root-MuiOutlinedInput-root {
  border-radius: 10px !important;
  width: 70%;
  transition: border-color 0.3s;
  padding: 0px;
  margin-top: 5px;
}

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 15px !important;
  font-family: cursive !important;
  font-size: 13px !important;
}

.calendar-container {
  font-family: "Arial", sans-serif;
  width: 15rem;
  box-shadow: 3;
  background-color: var(--card_color);
  height: 15rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
}

.days {
  display: grid;
  height: 75%;
  grid-template-columns: repeat(7, 0fr);
  justify-content: space-around;
  align-content: space-around;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  border: 1px solid #ddd;
  cursor: pointer;

}

.day:hover {
  background-color: #a22525;
}

.selected {
  background-color: #2196f3;
  color: white !important;
  border-radius: 5px;
  cursor: pointer;
}

.blocked {
  background-color: #ccc;
  cursor: not-allowed;
  border-radius: 5px;
}

.active {
  color: var(--dark_blue);
}

.button-91 {
  color: #fff;
  padding: 5px 10px;
  background-color:#068fff;
  background-image: radial-gradient(93% 87% at 87% 89%, rgba(0, 0, 0, 0.23) 0%, transparent 86.18%), radial-gradient(66% 66% at 26% 20%, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0) 69.79%, rgba(255, 255, 255, 0) 100%);
  box-shadow: inset -3px -3px 9px rgba(255, 255, 255, 0.25), inset 0px 3px 9px rgba(255, 255, 255, 0.3), inset 0px 1px 1px rgba(255, 255, 255, 0.6), inset 0px -8px 36px rgba(0, 0, 0, 0.3), inset 0px 1px 5px rgba(255, 255, 255, 0.6), 2px 19px 31px rgba(0, 0, 0, 0.2);
  border-radius: 14px;
  font-weight: bold;
  font-size: 16px;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  cursor: pointer;
}

.hover:hover {
  background-color: #38D2D2;
}

.disabled {
  color: var(--footer_home);
  cursor: not-allowed;
}

.selectedText {
  font-size: .9rem;
}

@media only screen and (max-width:600px) {
  .calendar-container {
    font-family: "Arial", sans-serif;
    width: 100%;
    box-shadow: 3;
    height: 15rem;
  }
}