.customTextField {
  border-radius: 5px;
  width: 100%;
  border: none;
  padding: 5px;
  font-weight: 500;
  font-size: 15px;
  outline:1.5px solid #08d5c9
  /* font-family: OpenSans; */
}
.customTextField:focus {
  outline: 1.5px solid #08d5c9;
}
.customTextField::placeholder {
  /* font-family: OpenSans; */
  font-size: 15px;
  font-weight: 500;
}
