
  
  .divider-container {
    display: flex;
    align-items: center;
    margin: 5px 0;
    width:100%
  }
  
  .divider-line {
    flex-grow: 1;
    border-top: 1.2px solid var(--primary_test_color); 
  }
  
  .divider-text {
    padding: 0 15px;
    font-weight: 600;
    color: var(--primary_test_color); 
    white-space: nowrap;
    text-align: center;
  }
  