/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

.root {
    font-family: 'Open Sans', 'sans-serif', 'Roboto', ;
}

.centerComponent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorMsgText {
    color: #fff;
    font-size: small;
}

