body {
  margin: 0;
  padding: 0;
  overflow: visible !important;
}

.App {
  background: var(--body_backGround);
  height: 100%;
}


:root {
  --body_backGround: linear-gradient(216deg, rgba(191, 239, 255, 1) 14%, rgba(255, 255, 255, 1) 29%, rgba(253, 254, 254, 1) 71%, rgba(191, 239, 255, 1) 96%);
  --primary_test_color: #58595b;
  --dark_blue: #58595b;
  --light_gray: #7e7e7e;
  --black: black;
  --card_color: #fff;
  --order_heading: #7e7e7e;
  --white_mode: #fff;
  --svg_color: #31a6ff;
  --dashbord_Appbar: #bfefff;
  --lightBlue: #bfefff;
  --wallet_card: #0690fd;
  --secondary_card: #fff;
  --sidebar_hover: #bfefff;
  --secondary_text: #068fff;
  --homepage_cards: #bfefff;
  --info_card: #068fff;
  --footer_home: #caedff;
  --header_test_color: #068fff;
  --footer_icon_color: #068fff;
  --app_bar: #fff;
  --footer_indicator: #068fff;
  --check_out_card: #fff;
  --button_color_sec: #2c4979;
  --svg_icon_color: #068FFF;
  --crate_color: #018786;
  --giftcard_color: #038ec2;
  --giftcard_blue: #038ec2;
  --secondary_blue:#ABE5F9;
  --theme_blue:#fff

}

[dark-theme="dark"] {
  --body_backGround: url("../src/Assets/newMobileIcon/DarkThemeBackgroundlg.svg");
  --primary_test_color: white;
  --dark_blue: white;
  --info_card: #1f4d95;
  --light_gray: #fff;
  --black: #fff;
  --card_color: #2c4979;
  --order_heading: #63b6ff;
  --white_mode: #58595b;
  --svg_color: #fff;
  --dashbord_Appbar: #1e3e70;
  --lightBlue: #2c4979;
  --wallet_card: #2c4979;
  --secondary_card: #1f4d95;
  --sidebar_hover: #0e2954;
  --secondary_text: #fff;
  --homepage_cards: #2c4979;
  --footer_home: #0d316a;
  --header_test_color: #63b6ff;
  --app_bar: #1e3e70;
  --footer_icon_color: #2c4979;
  --footer_indicator: #068fff;
  --check_out_card: #0e3469;
  --button_color_sec: #caedff;
  --svg_icon_color: #A0D4FF;
  --crate_color: #51BA89;
  --giftcard_color: #038ec2;
  --giftcard_blue: #ABE5F9;
  --secondary_blue:#ABE5F9;
  --theme_blue:#ABE5F9

}


/* Media query for smaller screens (e.g., max-width 768px) */
@media screen and (max-width: 768px) {
  [dark-theme="dark"] {
    --body_backGround: url("../src/Assets/newMobileIcon/DarkThemeBackground.svg");
  }
}

.custom-toast {
  width: 300px !important;
  /* Adjust the width as needed */
  height: 80px !important;
  /* Adjust the height as needed */
  right: 20px !important;
  /* Adjust the position from the right */
  bottom: 20px !important;
  /* Adjust the position from the bottom */
}