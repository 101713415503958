.banner {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  height: 50px;
  background-color: #2ecc71;
  color: white;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  transition: top 0.5s ease-in-out;
}

.banner.visible {
  top: 0;
}

dateButton {
  background-color: #fff;
  color: #333;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #333;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s ease;
}

dateButton:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  transition: all 0.3s ease;
}

dateButton:hover:before {
  border-color: #333;
}

dateButton:hover {
  color: #fff;
  background-color: #333;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}

