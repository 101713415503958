.otp-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.otp-input {
  width: 2rem;
  height: 2rem;
  margin: 0 5px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  background-color: white;
}

.otp-input:focus {
  outline: none;
  border-color: #007bff;
}
.inputStyle {
  width: 50% !important;
  height: 45px;
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}

.inputStyle {
  border-radius: 7px;
  border: 0px;
  margin-left: 8px;
  margin-right: 8px;
  background: #dddddd;
  font-size: 20px;
}