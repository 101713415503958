/* ------------- login.js---------------  */
.auth-auth-main{
    width: 100vw;
    height: 100vh;
    padding: 40px 80px;
}
.box-align{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}
.auth-auth-auth-main{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%;
    height: 100%;
}
.auth-main{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
}
.auth-img-ui{
    width:50%;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auth-img-ui img{
    text-align: center;
    width: 80%;
    height: 80%;
}
.auth-form{
    padding-top: 30px;
    width:50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.auth-header{
    text-align: center;
    margin-bottom: 20px;
}
.auth-tabs{
    width: 300px;
    border-radius: 8px;
    background-color: #D3D3D3;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.auth-tabs-login{
    border-radius: 10px;
    color : #000;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}
.auth-tabs-signup{
    border-radius: 10px;
    font-size: 23px;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}
.signup-form{
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.signup-inputs{
    display: flex;
    width: 300px;
    margin-top: 20px;
    padding: 10px;
    border: 1px solid black;
    border-radius: 5px;
}
.signup-inputs input{
    width: 100%;
    border: none;
}
.signup-inputs input:focus{
    outline: none;
}
.signup-inputs input::placeholder{
    text-align: center;
}
.signup-termsconditions{
    margin-top: 10px;
    width: 400px;
}
.auth-button{
    margin-top: 30px;
}
.auth-button button{
    border-radius: 5px;
    width: 300px;
    font-weight: bold;
    border: none;
    background-color: #3F00FF;
    color: #fff;
    font-size: 20px;
    padding: 5px 30px 5px 30px;
}
.signup-forgotpass{
    margin-top: 20px;
    color: #3F00FF;
    align-self: flex-start;
    display: flex;
}
.signup-rememberme{
    margin-top: 20px;
    align-self: flex-start;
}

@media only screen and (max-width: 1000px) {
    .signup-inputs{
        width: 250px;
        margin-top: 15px;
    }
    .auth-tabs{
        width: 250px;
    }
    .auth-tabs-login{
        font-size: 18px;
    }
    .auth-tabs-signup{
        font-size: 18px;
    }
    .signup-termsconditions{
        margin-top: 10px;
        width: 300px;
        font-size: 12px;
    }
    .auth-button{
        margin-top: 20px;
    }
    .auth-button button{
        width: 250px;
    }
}
@media only screen and (max-width: 800px){
    .auth-auth-main{
        padding: 50px;
    }
}
@media only screen and (max-width: 700px){
    .auth-auth-main{
        padding: 0;
    }
    .auth-main{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .auth-form{
        padding: 15px;
    }
    .signup-inputs{
        padding: 5px;
        width: 200px;
        margin-top: 10px;
    }
    .auth-header{
        margin-bottom: 10px;
    }
    .signup-form{
        align-self: center;
    }
    .signup-inputs input{
        font-size: 12px;
    }
    .signup-inputs input::placeholder{
        font-size: 12px;
    }
    .auth-img-ui{
        margin-top: 10px;
    }
    .auth-img-ui img{
        width: 100%;
        height: 100%;
    }
    .auth-tabs-login{
        font-size: 15px;
    }
    .auth-tabs-signup{
        font-size: 15px;
    }
    .auth-tabs{
        width: 200px;
    }
    .signup-termsconditions{
        margin-top: 10px;
        width: 250px;
        font-size: 10px;
    }
    .auth-button button{
        font-size: 15px;
        width: 200px;
    }
    .auth-button{
        margin-top: 10px;
    }
    .signup-forgotpass{
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        font-size: 13px;
    }
    .signup-rememberme{
        margin-top: 10px;
        font-size: 12px;
    }
    .auth-header h2{
        font-size: 20px;
    }
}